<template>
  <el-form v-model="serverConfig">
    <el-form-item label="系统名称">
      <el-input v-model="serverConfig.name"></el-input>
    </el-form-item>
    <el-form-item label="企业图标">
      <label for="logoUpload">
        <img v-if="serverConfig.logo" :src="serverConfig.logo" class="logo" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </label>
      <input id="logoUpload" type="file" style="display: none" ref="upLoadFile" @change="handleLoadIMGLogo" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" size="small" @click="submit"> 确定 </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { getToken, getAuthorization } from '@/utils/auth';
import statusInclude from '@/utils/statusCode.js';
import axios from 'axios';
export default {
  props: {
    publicityDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      serverConfig: {
        name: '',
        logo: '',
      },
    };
  },
  computed: {
    corporations() {
      return this.publicityDetails?.corporations[0];
    },
  },
  created() {
    this.serverConfig.name = this.corporations.systemName;
    this.serverConfig.logo = this.corporations.systemIcon;
  },
  methods: {
    handleLoadIMGLogo() {
      const {
        files: [logo],
      } = this.$refs.upLoadFile;
      this.logoFile = logo;
      const uploadAccept = ['jpeg', 'jpg', 'png']; //上传图片允许的格式
      const text = logo.type.split('/')[1]; //后缀名
      if (!uploadAccept.includes(text)) return this.$message.warning('您上传的图片格式不符，请重新上传');
      const reader = new FileReader();
      reader.readAsDataURL(logo);
      reader.onload = () => (this.serverConfig.logo = reader.result);
    },
    submit() {
      const Authorization = getAuthorization() || '';
      const refreshToken = getToken() || '';
      const headers = { Authorization, refreshToken, 'Content-Type': 'multipart/form-data' };
      const formData = new FormData();
      formData.append('file', this.logoFile);
      formData.append('systemName', this.serverConfig.name);
      axios({
        method: 'post',
        url: `${base_url.server}/auth/authService/customizeSystemInfo`,
        data: formData,
        headers,
      })
        .then((response) => {
          if (!statusInclude(response.data.code)) return this.$message.error(`系统配置更新失败：${response.data.reason}`);
          this.$message.success('系统配置成功');
          location.reload();
        })
        .catch((error) => {
          this.$message.error('系统配置失败');
          throw new Error(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  width: 120px;
}
</style>
