<template>
  <el-tabs class="personal-attr-config-wrap" v-model="activeName">
    <el-tab-pane label="基本信息" name="first">
      <PersonalBaseInfo :publicity-details="publicityDetails" />
    </el-tab-pane>
    <el-tab-pane label="修改密码" name="second">
      <UpdatePassword />
    </el-tab-pane>
    <!-- v-if="publicityDetails.roleId === 'R0006'"  && hiddenBindWeChat -->
    <el-tab-pane label="账号绑定" name="third" v-if="!hasWeChatInfo && !sky">
      <div class="account-authentication-wrap">
        <div class="account-authentication-floor">
          <div class="account-base-config-wrap">
            <i class="iconfont wechat-icon icon-weixin"></i>
            <div>
              <p>绑定微信</p>
              <p>当前未绑定微信账号</p>
            </div>
          </div>
          <el-button type="text" @click="handleBindWeChatInfo">更改绑定</el-button>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="绑定企业" name="fourth" v-if="!hasSetupCorporation">
      <MountCorporation />
    </el-tab-pane>
    <el-tab-pane label="企业邀请码" name="fifth" v-if="isEnterpriseAdministrator">
      <GenerateCorporationCode />
    </el-tab-pane>
    <el-tab-pane label="系统配置" name="serverConfig" v-if="isEnterpriseAdministrator">
      <ServerConfig :publicity-details="publicityDetails" />
    </el-tab-pane>
  </el-tabs>
</template>
<script>
import PersonalBaseInfo from './Form/PersonalBaseInfor.vue';
import UpdatePassword from './Form/UpdatePassword.vue';
import MountCorporation from './Form/MountCorporation.vue';
import GenerateCorporationCode from './Form/GenerateCorporationCode.vue';
import ServerConfig from './Form/ServerConfig.vue';
import { getToken } from '@/utils/auth';
import Cookies from 'js-cookie';
import statusInclude from '../../../../utils/statusCode';

const refreshToken = getToken() || '';

const window_wechat_bind_code = 'wechat-bind-window';
const url_group = ['https://101.132.121.145', 'http://10.127.20.172'];

export default {
  components: {
    PersonalBaseInfo,
    UpdatePassword,
    MountCorporation,
    GenerateCorporationCode,
    ServerConfig,
  },
  props: {
    publicityDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    isEnterpriseAdministrator() {
      return this.publicityDetails?.roleId === 'R0004';
    },

    hasSetupCorporation() {
      return this.publicityDetails?.corporations?.length;
    },
    wechatAuthCode() {
      return 'weChatCode' + new Date().getTime() + Math.random();
    },

    hasWeChatInfo() {
      return this.publicityDetails.nickName;
    },
    hiddenBindWeChat() {
      return this.publicityDetails?.roleId === 'R0005' || this.publicityDetails?.roleId !== 'R0006';
    },
    sky() {
      return url_group.findIndex((item) => window.location.href.split('#')[0].indexOf(item) > -1) > -1;
    },
  },
  data() {
    return {
      activeName: 'second',
      dialogVisible: false,
      _window_wechat_bind: null,
    };
  },
  methods: {
    handleBindWeChatInfo() {
      //获取微信二维码生成地址
      const url = this.jointSrc(refreshToken);
      function openwindow(url, name, iWidth, iHeight) {
        var url; //转向网页的地址;
        var name; //网页名称，可为空;
        var iWidth; //弹出窗口的宽度;
        var iHeight; //弹出窗口的高度;
        //window.screen.height获得屏幕的高，window.screen.width获得屏幕的宽
        var iTop = (window.screen.height - 30 - iHeight) / 2; //获得窗口的垂直位置;
        var iLeft = (window.screen.width - 10 - iWidth) / 2; //获得窗口的水平位置;
        return window.open(url, name, 'height=' + iHeight + ',,innerHeight=' + iHeight + ',width=' + iWidth + ',innerWidth=' + iWidth + ',top=' + iTop + ',left=' + iLeft + ',toolbar=no,menubar=no,scrollbars=auto,resizeable=no,location=no,status=no');
      }

      //打开新窗口
      this._window_wechat_bind = openwindow(url, window_wechat_bind_code, 500, 540);
      // window.open(url, window_wechat_bind_code, 'toolbar=no,menubar=no,resizable=yes,height=690px,width=820px,z-look=yes');
      this.bindWeChatToUserInfo(refreshToken);
    },

    //拼接请求地址
    jointSrc(callback) {
      return `http://api.skysys.cn:18089/wechat/api/weChat/wechatBindCode?callback=${callback}`;
    },

    /**
     * @description: 是否关闭window.open弹框
     * @return {*}
     */
    handleWindowClosed() {
      return this.wechat_code_window && this.wechat_code_window.closed;
    },

    /**
     * @description: 注销心跳检测
     * @params key bind wechat callback
     * @return {*}
     */
    clearHeartbeatInterval(key) {
      Cookies.remove(key);
      window.clearInterval(this.timer);
      this.timer = null;
    },

    //是否成功
    bindWeChatToUserInfo(key) {
      this.timer = setInterval(() => {
        const response = this.heartbeatDetection(key);
        if (response) {
          this.$message({ type: statusInclude(response.code) ? 'success' : 'error', message: response.reason });
          this.clearHeartbeatInterval(key);
          if (this._window_wechat_bind) this._window_wechat_bind.close();
        } else {
          this.handleWindowClosed() && this.clearHeartbeatInterval(key);
        }
      }, 1000);
    },

    heartbeatDetection(key) {
      try {
        const response = Cookies.get(key);
        return response && JSON.parse(response);
      } catch (error) {
        return false;
      }
    },
  },
  beforeDestroy() {
    this.clearHeartbeatInterval(refreshToken);
  },
};
</script>

<style lang="scss" scoped>
.personal-attr-config-wrap.el-tabs {
  background-color: var(--main-personal-form-fill);

  /deep/ .el-tabs__header {
    background-color: var(--main-personal-form-fill);
  }

  /deep/ .el-form-item {
    .el-form-item__content {
      .el-input {
        .el-input__inner {
          border-radius: 0px;
        }
      }

      .el-button {
        border-radius: 0px;
      }
    }
  }

  .account-authentication-wrap {
    .account-authentication-floor {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 10px;
      border: 1px solid var(--main-border-color);

      .wechat-icon {
        font-size: 50px;
        color: #2bae67;
        margin-right: 10px;
      }

      .account-base-config-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        div > p {
          padding: 0;
          margin: 0;
        }
      }
    }
  }
}
</style>
