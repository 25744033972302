<template>
	<el-form ref="passForm" label-position="top" :label-width="labelWidth" :model="password" :rules="rules">
    <!-- prop="oldPassword" -->
		<el-form-item label="原密码" >
			<el-input v-model="password.oldPassword" type="password" :show-password="true" autocomplete="off"></el-input>
		</el-form-item>
		<el-form-item label="新密码" prop="newPassword">
			<el-input v-model="password.newPassword" type="password" :show-password="true" autocomplete="off"></el-input>
		</el-form-item>
		<el-form-item label="确认密码" prop="verifyPassword">
			<el-input v-model="password.verifyPassword" type="password" :show-password="true" autocomplete="off"></el-input>
		</el-form-item>
		<el-form-item class="btn-row">
			<el-button size="small" type="primary" @click="submit('passForm')">保 存</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
import {checkPassword as validatePassword} from "@/utils/rules";
import {changePassword as updatePassword} from '@/api/user.js';
import statusInclude from "@/utils/statusCode";

export default {
	props: {
		labelWidth: {
			type: String,
			default: '80px',
		},
		labelPosition: {
			type: String,
			default: 'top',
		},
	},
	data() {
		const verifyPassword = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请再次输入密码'));
			} else if (value !== this.password.newPassword) {
				callback(new Error('两次输入密码不一致！'));
			} else {
				callback();
			}
		};
		return {
			password: {
				oldPassword: '',
				newPassword: '',
				verifyPassword: '',
			},
			rules: {
				oldPassword: [{required: false, validator: validatePassword, trigger: 'blur'}],
				newPassword: [{validator: validatePassword, trigger: 'blur'}],
				verifyPassword: [{validator: verifyPassword, trigger: 'blur'}],
			},
		};
	},
	methods: {
		submit(formName) {
			console.log(formName)
			this.$refs[formName].validate(async (valid) => {
				if (!valid) return this.$message.warning('请完善信息');
				const {newPassword, oldPassword} = this.password;
				try {
					const response = await updatePassword(oldPassword, newPassword);
					const {code, reason} = response;
					if (!statusInclude(code)) return this.$message.error(`请求错误：${reason}`);
					this.$message({type: 'success', message: `请求成功：${reason}`});
				} catch (error) {
					throw new Error(error);
				}
			})
		}
	}
};
</script>

<style></style>
