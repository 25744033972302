<template>
  <el-form :rules="rules" :model="data" label-position="top">
    <el-form-item label="企业编号">
      <el-input v-model="data.cpId" :disabled="true"></el-input>
    </el-form-item>
    <el-form-item label="企业邀请码" prop="inviteCode">
      <el-input v-model="data.inviteCode">
        <el-button slot="append" icon="el-icon-s-order" v-clipboard:copy="templateString" v-clipboard:success="handleCopySuccess" v-clipboard:error="handleCopyError">
          <el-tooltip class="item" effect="dark" content="复制企业邀请码" placement="top-start">
            <span>邀请用户</span>
          </el-tooltip>
        </el-button>
      </el-input>
    </el-form-item>
    <el-form-item label="过期时间">
      <el-date-picker v-model="data.failureTime" type="datetime" placeholder="选择有效时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button size="small" type="primary" @click="handleInviteCode(data)">
        {{ hasInviteCode ? '确认修改' : '生成邀请码' }}
      </el-button>
      <el-button size="small">取消</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import statusInclude from '@/utils/statusCode';
import { uInviteCode as validateInviteCode } from '@/utils/rules.js';
export default {
  data() {
    return {
      templateString: '',
      data: {
        cpId: '', //企业编号
        inviteCode: '', //企业邀请码
        failureTime: '', //邀请码过期时间
      },
      rules: {
        inviteCode: [{ required: true, validator: validateInviteCode, trigger: 'blur' }],
      },
    };
  },
  computed: {
    hasInviteCode() {
      return false;
    },
  },
  methods: {
    handleCopySuccess(e) {
      this.$message.success('复制成功!');
    },
    handleCopyError(e) {
      this.$message.error('复制失败!');
    },

    //生成&编辑企业邀请码
    async handleInviteCode(params) {
      const { code, reason, data } = this.hasInviteCode ? this.updateEnterpriseInvitationCode(params) : await generateCpInvitationCode(cpId);
      if (!statusInclude(code)) return this.$message.error(reason);
    },
  },
};
</script>

<style></style>
