<template>
  <ul class="personal-attr-wrap">
    <li>
      <i class="iconfont icon-yonghu"></i>
      <span>{{ publicityDetails.idcardName }}</span>
    </li>
    <li v-if="publicityDetails.corporations && publicityDetails.corporations.length > 0">
      <i class="iconfont icon-a-yewuguanliqiyeguanli"></i>
      <span>{{ publicityDetails.corporations[0].cpName }}</span>
    </li>
    <li v-if="accountType !== false">
      <i class="iconfont icon-type"></i>
      <el-tag size="mini" :type="publicityDetails.isTrial === 0 ? '' : 'warning'">{{ accountType }}</el-tag>
    </li>
    <li v-if="this.publicityDetails.isTrial">
      <i class="iconfont icon-shijian"></i>
      <span>{{ publicityDetails.effectiveDate }}</span>
    </li>
    <li>
      <i class="iconfont icon-shoujihao"></i>
      <span>{{ publicityDetails.userMobile }}</span>
    </li>
    <li v-if="publicityDetails && publicityDetails.nickName">
      <i class="iconfont icon-weixin"></i>
      <span>{{ publicityDetails.nickName }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    publicityDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    accountType() {
      return this.publicityDetails.isTrial === undefined ? false : this.publicityDetails.isTrial === 0 ? '永久账号' : '试用账号';
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-attr-wrap {
  margin-top: 18px;
  text-align: left;
  list-style: none;
  color: var(--color-primary);
  li {
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 14px;
    // color: var(--color-primary);
    // span {
    //   color: var(--color-primary);
    // }
    i {
      padding-right: 15px;
      font-size: 16px;
      font-weight: 900;
      &.icon-shoujihao {
        font-size: 18px;
      }
    }
  }
}
</style>
