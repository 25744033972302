<template>
  <el-form ref="AuthenticationForm" :rules="rules" :model="data" label-position="top">
    <el-form-item label="企业编号" prop="cpId">
      <el-input v-model="data.cpId"></el-input>
    </el-form-item>
    <el-form-item label="邀请码" prop="inviteCode">
      <el-input v-model="data.inviteCode"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button size="small" type="primary" @click="submit('AuthenticationForm')"> 提交企业信息 </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { setupRoleInEnterprise } from '@/api/enterprise.js';
import statusInclude from '@/utils/statusCode.js';
export default {
  data() {
    return {
      data: {
        cpId: '',
        inviteCode: '',
      },
      rules: {
        cpId: [{ required: true, message: '请输入企业编号', trigger: 'blur' }],
        inviteCode: [{ required: true, message: '请输入邀请码', trigger: 'blur' }],
      },
    };
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        // console.log(data);
        if (valid) {
          try {
            const { cpId, inviteCode } = this.data;
            const tmpCpId = cpId.replace(/\s+/g, '');
            const tmpInviteCode = inviteCode.replace(/\s+/g, '');
            const { code, reason } = await setupRoleInEnterprise({
              cpId: tmpCpId,
              inviteCode: tmpInviteCode,
            });
            if (!statusInclude(code)) return this.$message.error(`绑定失败：${reason}`);
            this.$message({ type: 'success', message: '企业信息绑定成功' });
            // this.resetAuthenticationInfo();
          } catch (err) {
            this.$message.error(`服务器错误:${err}`);
          }
        }
      });
    },
  },
};
</script>

<style></style>
