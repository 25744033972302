<template>
  <el-form ref="BaseInfoForm" label-position="top" class="personal-base-infor-form" :model="baseInfor" :rules="rules">
    <el-form-item label="姓名" prop="idcardName">
      <el-input class="right-angle" v-model="baseInfor.idcardName"></el-input>
    </el-form-item>
    <el-form-item label="邮箱" prop="userEmail">
      <el-input class="right-angle" v-model="baseInfor.userEmail"></el-input>
    </el-form-item>
    <el-form-item label="用户名" prop="userName">
      <el-input class="right-angle" v-model="baseInfor.userName"></el-input>
    </el-form-item>
    <el-form-item label="手机号" prop="userMobile">
      <el-input class="right-angle" v-model="baseInfor.userMobile"></el-input>
    </el-form-item>
    <el-form-item>
      <el-button size="small" type="primary" @click="submit('BaseInfoForm')"> 保 存</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { editUserInfomation } from '@/api/user.js';
import { checkName as validateName, checkEmail as validateEmail, checkStr as validateNickname, checkMobile as validateMobile } from '@/utils/rules.js';
export default {
  props: {
    publicityDetails: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      baseInfor: {
        idcardName: '',
        userEmail: '',
        userName: '',
        userMobile: '',
      },
      rules: {
        idcardName: [{ validator: validateName, trigger: 'blur' }],
        userEmail: [{ validator: validateEmail, trigger: 'blur' }],
        userName: [{ validator: validateNickname, trigger: 'blur' }],
        userMobile: [{ validator: validateMobile, trigger: 'blur' }],
      },
    };
  },
  created() {
    this.baseInfor = JSON.parse(JSON.stringify(this.publicityDetails));
    console.log(this.baseInfor);
    // Object.keys(this.baseInfor).forEach((key) => {
    //   this.baseInfor[key] = this.publicityDetails[key];
    // });
    console.log(this.baseInfor);
  },
  methods: {
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          editUserInfomation(this.baseInfor).then((response) => {
            console.log(response);
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-base-infor-form {
}
</style>
