<template>
	<div class="personal-details-wrap">
		<div class="personal-details-content">
			<span @click="handleTouchIMG">
				<el-avatar fit="fill" size="large" :src="publicityDetails.userAvater" style="width: 100px; height: 100px"></el-avatar>
			</span>
			<div class="personal-full-username">{{ publicityDetails.userName }}</div>
			<div class="signature" v-if="false">{{ publicityDetails.signature }}</div>
			<el-tag style="margin-top: 15px" size="small">{{ publicityDetails.roleName }}</el-tag>
			<PersonalAttribute :publicity-details="publicityDetails"/>
		</div>
		<el-dialog :close-on-click-modal="false" title="头像裁剪" :visible.sync="dialogVisible" width="454px">
			<div style="height: 274px;width: 274px">
				<VueCropper
					ref="cropper"
					:img="options.img"
					:info="true"
					:autoCrop="options.autoCrop"
					:autoCropWidth="options.autoCropWidth"
					:autoCropHeight="options.autoCropHeight"
					:fixedBox="options.fixedBox"
					@realTime="realTime"
				/>
			</div>
			<div class="preview-area">
				<div class="upload-preview rounded-corners">
					<el-image :src="previews.url" :style="previews.img" v-show="previews.url"></el-image>
				</div>
				<div class="upload-preview">
					<el-image :src="previews.url" :style="previews.img" v-show="previews.url"></el-image>
				</div>
			</div>
			
			<span slot="footer">
				<el-button size="mini" style="margin-right:10px">
					<label for="avatar">
						上传图片
					</label>
				</el-button>
				<input ref="AvatarFileUpload" id="avatar" type="file" style="display: none" @change="uploadIMG"/>
				<el-button size="mini" @click="submit">保存更新</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import PersonalAttribute from './PersonalAttribute.vue';
import {VueCropper} from 'vue-cropper';
import {getAuthorization, getToken} from '@/utils/auth';

import {api_url} from '@/utils/BASE_URL.js'
import statusInclude from "@/utils/statusCode";
import axios from 'axios';


export default {
	components: {
		VueCropper,
		PersonalAttribute,
	},
	props: {
		publicityDetails: {
			type: Object,
			default() {
				return {};
			},
		},
	},
	data() {
		return {
			dialogVisible: false,
			previews: {},
			options: {
				img: '', //裁剪图片的地址
				info: true, //裁剪框的大小信息
				outputSize: 0.8, // 裁剪生成图片的质量
				outputType: 'png', // 裁剪生成图片的格式
				canScale: false, // 图片是否允许滚轮缩放
				autoCrop: true, //是否默认生成截图框
				autoCropWidth: 128, //默认生成截图框宽度
				autoCropHeight: 128,
				fixedBox: false, // 固定截图框大小 是否允许改变
				fixed: true, //是否开启截图框宽高固定比例
				fixedNumber: [1, 1], //截图框的宽高比例
				original: false, // 上传图片按照原始比例渲染
				centerBox: true, // 截图框是否被限制在图片里面
				infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
			}
		};
	},
	methods: {
		uploadIMG() {
			const file = this.$refs.AvatarFileUpload.files[0];
			this.avatarName = file.name; //包含后缀
			const uploadAccept = ['jpeg', 'jpg', 'png']; //上传图片允许的格式
			const text = file.type.split('/')[1]; //后缀名
			if (!uploadAccept.includes(text)) return this.$message.warning('您上传的图片格式不符，请重新上传');
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => {
				
				this.options.img = reader.result; //base64
				console.log(this.options.img)
			};
		},
		handleTouchIMG() {
			this.dialogVisible = true
		},
		realTime(data) {
			console.log(data)
			this.previews = data;
		},
		
		//base64转换为file类型
		base64toFile(base64, filename) {
			let arr = base64.split(','),
				mime = arr[0].match(/:(.*?);/)[1],
				bstr = atob(arr[1]),
				n = bstr.length,
				u8arr = new Uint8Array(n);
			while (n--) {
				u8arr[n] = bstr.charCodeAt(n);
			}
			return new File([u8arr], filename, {type: mime});
		},
		
		getBase64(imgUrl) {
			window.URL = window.URL || window.webkitURL;
			var xhr = new XMLHttpRequest();
			xhr.open("get", imgUrl, true);
			// 至关重要
			xhr.responseType = "blob";
			xhr.onload = function () {
				if (this.status == 200) {
					//得到一个blob对象
					var blob = this.response;
					console.log("blob", blob)
					// 至关重要
					let oFileReader = new FileReader();
					oFileReader.onloadend = function (e) {
						// 此处拿到的已经是 base64的图片了
						let base64 = e.target.result;
					};
					oFileReader.readAsDataURL(blob);
					//====为了在页面显示图片，可以删除====
					var img = document.createElement("img");
					img.onload = function (e) {
						window.URL.revokeObjectURL(img.src); // 清除释放
					};
					let src = window.URL.createObjectURL(blob);
					img.src = src
					//document.getElementById("container1").appendChild(img);
					//====为了在页面显示图片，可以删除====
					
				}
			}
			xhr.send();
		},
		
		// 更新头像
		submit() {
			const Authorization = getAuthorization() || '';
			const refreshToken = getToken() || '';
			try {
				this.loading = true;
				this.$refs.cropper.getCropData(async base64 => {
					let file_img = this.base64toFile(base64, this.avatarName);
					const param = new FormData();
					const headers = {Authorization, refreshToken, 'Content-Type': 'multipart/form-data'};
					param.append('file', file_img);
					axios({method: 'post', url: api_url.updateUserProfilePhoto, data: param, headers})
						.then(response => {
							if (!statusInclude(response.data.code)) return this.$message.error(`头像上传失败：${response.data.reason}`);
							this.$message.success('上传头像成功');
							this.$emit('update:setPersonalIMG', base64)
						})
						.catch(error => {
							console.log(error);
						});
				});
			} catch (error) {
				this.$message.error('上传头像失败');
			} finally {
				this.loading = false;
				this.isVisible_editAvatarDialog = false;
			}
		}
		
	}
};
</script>

<style lang="scss" scoped>
.personal-details-wrap {
	width: 100%;
	height: 100%;
	padding: 20px;
	
	.personal-details-content {
		padding: 20px;
		text-align: center;
		
		.personal-full-username {
			margin-top: 15px;
			font-size: 24px;
			font-weight: 500;
			color: var(--color-primary);
		}
		
		.signature {
			margin-top: 8px;
		}
	}
	
	.el-dialog__wrapper {
		/deep/ .el-dialog {
			
			.el-dialog__body {
				display: flex;
				flex-direction: row;
				
				.preview-area {
					margin-left: 20px;
					display: flex;
					flex-direction: column;
					
					.upload-preview {
						top: 50%;
						width: 128px;
						height: 128px;
						
						border: 1px solid var(--main-border-color);
						overflow: hidden;
						
						&.rounded-corners {
							margin-bottom: 20px;
							border-radius: 50%;
						}
					}
				}
			}
			
			
		}
	}
	
	
}
</style>
