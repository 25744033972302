<template>
  <el-card class="personal-details page-container">
    <el-row :gutter="10" class="user-information-config">
      <el-col class="user-information" :span="8">
        <PersonalDetails :publicity-details="publicityDetails" @update:setPersonalIMG="setPersonalIMG" />
      </el-col>
      <el-col class="personal-config-wrap" :span="16">
        <PersonalAttributeConfig :publicity-details="publicityDetails" />
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import PersonalDetails from './components/PersonalDetails.vue';
import PersonalAttributeConfig from './components/PersonalAttributeConfig.vue';

import { getUserInformation, getUserCorporationsInformation } from '@/utils/auth';

export default {
  name: 'Personal',
  components: {
    PersonalDetails,
    PersonalAttributeConfig,
  },
  data() {
    return {
      publicityDetails: {
        roleId: '',
        idcardName: '',
        corporations: '',
        roleName: '',
        userName: '',
        userMobile: '',
        userAvater: '',
        userEmail: '',
        effectiveDate: '',
        isTrial: '',
        nickName: '',
      },
    };
  },
  created() {
    const { userInformation: bufferUserInformation } = getUserInformation();
    const corporations = getUserCorporationsInformation();
    bufferUserInformation.corporations = corporations;
    if (bufferUserInformation && bufferUserInformation.trialInfo) {
      const {
        trialInfo: { effectiveDate, isTrial },
      } = bufferUserInformation;

      const temporary = Object.assign({ ...bufferUserInformation, effectiveDate, isTrial });
      delete temporary.trialInfo;
      this.publicityDetails = temporary;
    } else {
      console.log(bufferUserInformation);
      this.publicityDetails = bufferUserInformation;
    }

    //TODO 测试数据
    this.publicityDetails.signature = '富在术数，不在劳身；利在势居，不在力耕。';
  },
  mounted() {
    const el = document.getElementsByClassName('el-col');
    Array.from(el).forEach((item) => item.removeAttribute('style'));
  },
  methods: {
    setPersonalIMG(img) {
      this.publicityDetails.userAvater = img;
    },
  },
};
</script>

<style lang="scss" scoped>
.personal-details {
  > .el-card__header {
    height: 0;
  }

  /deep/ .el-card__body {
    height: 100% !important;
    background: var(--main-personal-form-fill);

    .user-information-config {
      height: 100%;

      // background-color: aquamarine;
      .el-col {
        height: 100%;
        padding-left: 0;

        &.user-information {
          border-right: 1px solid var(--main-border-color);
        }

        &.personal-config-wrap {
          padding: 20px;
        }
      }
    }
  }
}
</style>
