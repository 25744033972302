import { render, staticRenderFns } from "./PersonalBaseInfor.vue?vue&type=template&id=f1a1059e&scoped=true&"
import script from "./PersonalBaseInfor.vue?vue&type=script&lang=js&"
export * from "./PersonalBaseInfor.vue?vue&type=script&lang=js&"
import style0 from "./PersonalBaseInfor.vue?vue&type=style&index=0&id=f1a1059e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a1059e",
  null
  
)

export default component.exports